import gsap from 'gsap';

export const isElementInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  return rect.top <= windowHeight && rect.bottom > 0;
};

export const itemGsap = (item, options = {
  opacity: 1,
  y: 0,
  duration: 1,
}) => {
  gsap.to(item, {
    ...options,
  });
};
